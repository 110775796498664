import styled from "styled-components";
import { Link } from "./Utils";
import Text from "./Text";
import Join from "./Footer-join";

import { Twitter, Facebook, Linkedin, Instagram, Youtube, Tiktok } from "./SocialIcons/";

const Img = styled.img`
  display: inline-block;
`;

const Wrap = styled.footer`
  margin-top: 32px;
  background-color: #0c273e;
  ${ props => props.theme && props.themeContext && props.themeContext === 'GCI' && `background-color: ${props.theme.color.gci};` }
  padding: 0;
  padding-right: ${props=>props.isWatchlist?'80px':'0'};
  ${Text} {
  font-size:0.85rem;
  }

  }
  `

const Socials = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid #73859c;
  > div {
  max-width: 1296px;
  padding: 0 24px;
  margin:auto;
  }
  ${Link} {
  display:inline-block;
  padding:0 12px;
  text-align:center;
  }
  ${Text} {
  font-size: 1.4rem;
  display:inline-block;
  height: 40px;
  vertical-align: text-bottom;
  padding-right:24px;
  }
`


const Links = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid #73859c;
  > div {
  display:flex;
  justify-content: space-between;
  max-width:1296px;
  padding: 0 24px;
  margin: auto;
  ${Link} {
  
  }
  }
  
`;

const Legal = styled.div`
  padding: 24px 0;
  > div {
  max-width: 1296px;
  padding: 0 24px;
  margin:auto;
  }
  ${Link} {
  color:#fff;
  &:hover {
  text-decoration: underline;
  }
`;

const FooterLink = styled(Link)`
color:#fff;
  font-weight: bold;
  font-size:1.5rem;
&:hover {
text-decoration: underline;
}
`


const Footer = ({ isWatchlist, compact, themeContext, ...props}) => {
  return <Wrap isWatchlist={isWatchlist} themeContext={themeContext}>
    {!compact && <Join loggedIn={props.loggedIn} />}
    <Links>
      <div>
        <FooterLink href="/about/">About</FooterLink>
        <FooterLink href="mailto:hello@voxmarkets.co.uk">Contact</FooterLink>
        <FooterLink href="/terms-and-conditions/">T&C's</FooterLink>
        <FooterLink href="/careers/">Careers</FooterLink>
      </div>
    </Links>
    <Socials>
      <div>
        <Text white as="span">Follow us on:</Text>
        <FooterLink href="https://twitter.com/VOXmarkets" target="_blank"><Twitter /></FooterLink>
        <FooterLink href="https://www.linkedin.com/company/vox-markets" target="_blank"><Linkedin /></FooterLink>          
        <FooterLink href="https://www.facebook.com/VoxMarkets" target="_blank"><Facebook /></FooterLink>
        <FooterLink href="http://youtube.com/@Vox-Markets" target="_blank"><Youtube /></FooterLink>         
        <FooterLink href="https://www.instagram.com/voxmarkets/" target="_blank"><Instagram /></FooterLink>
        <FooterLink href="https://www.tiktok.com/@voxmarkets/" target="_blank"><Tiktok /></FooterLink>
      </div>
    </Socials>
    <Legal>
      <div>
        <Text white as="p">Vox Markets Limited is registered in England with the Company Registration number 09172648,  VAT registration number 196920664.</Text>
        <Text white as="p">Registered address: First Floor Office Suite 3, 13 Cathedral Road, Cardiff, United Kingdom, CF11 9HQ</Text>
        <br />
        <Link href="/privacy-and-cookie-policy/">
          <Text white as="span">Cookie and Privacy Policy</Text>
        </Link>
      </div>
    </Legal>
  </Wrap>
}

export default Footer;
